<template>
    <div>
        <Disclosure v-slot="{ open, close }" v-if="item[inputChildren] && item[inputChildren].length">
            <DisclosureButton :class="['option--line selected-list w-full flex items-center']">
                <ChevronRightIcon
                    :class="[open ? 'rotate-90' : '', 'mr-3 flex-shrink-0 transform transition-colors ease-in-out duration-150 h-3.5 w-3.5']"/>
                <span class="flex-1 text-left">{{ item[inputLabel] }}</span>
                <span class="cursor-pointer bg-gray-200 dark:bg-darkgray-500 hover:bg-gray-300 dark:hover:bg-darkgray-600 rounded-md px-2 py-1 text-xs"
                      @click.prevent="setValue()" v-if="branchSelectable">
                    <template v-if="!selected(item)">{{ $t('app.select') }}</template>
                    <template v-else>{{ $t('app.remove') }}</template>
                </span>
            </DisclosureButton>
            <DisclosurePanel class="transition-all duration-500 ease-in-out" :class="nextIndexPadding">
                <template v-for="subItem in item[inputChildren]" :key="item[inputLabel]">
                    <SelectOption :item="subItem" :input-label="inputLabel" :input-value="inputValue" :index="newIndex"
                                  :selected="selected" :branch-selectable="branchSelectable"
                                  :input-children="inputChildren"></SelectOption>
                </template>
            </DisclosurePanel>
            <button
                :ref="(el) => (elements[idx] = el)"
                :data-id="item[inputValue]"
                v-show="false"
                @click="doClose(close)"
            ></button>
            <AccordionStateEmitter :show="open" @show="hideOther(item[inputValue])"/>
        </Disclosure>
        <div class="option--line selected-list" @click.prevent="setValue()" v-else>
            <span v-if="selected(item)"
                  class="absolute inset-y-0 right-0 flex items-center pr-3 text-success-600">
                <CheckIcon class="w-5 h-5" aria-hidden="true"/>
            </span>
            {{ item[inputLabel] }}
        </div>
    </div>
</template>

<script setup>
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue'
import useAccordion from "../../composables/useAccordion"
import AccordionStateEmitter from './AccordionStateEmitter.vue'

const {elements, hideOther, doClose} = useAccordion()
import {ChevronRightIcon, CheckIcon} from "@heroicons/vue/24/solid";

const emitter = inject("emitter")

function subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input]
    return paths.some(path => {
        return route.path.indexOf(path) === 0 // current path starts with this path string
    })
}

const props = defineProps({
    item: {
        required: true
    },
    inputLabel: {
        required: true
    },
    inputValue: {
        required: true
    },
    inputChildren: {
        required: true
    },
    index: {
        default: 1
    },
    selected: {
        default: null
    },
    branchSelectable: {
        type: Boolean,
        default: false,
    },
    class : {
        default : null
    }
})

const newIndex = ref(props.index + 1)

const nextIndexPadding = computed(() => {
    return "pl-" + (props.index+1)
})

const setValue = () => {
    emitter.emit("selectedValueInSelectOption", props.item);
}

</script>

<style scoped>

</style>
