<template>
  <div>
    <FormLabel class="text-primary" :is-required="isRequired" v-if="label">
      {{ label }}

      <template #helper>
        <div v-if="$slots.rightHelperArea">
          <slot name="rightHelperArea"></slot>
        </div>
        <span class="text-xs text-gray-600" v-else-if="labelHelper">{{ labelHelper }}</span>
      </template>
    </FormLabel>

    <OnClickOutside @trigger="hide">
      <div class="relative">
        <FormInput ref="searchInput"
                   :id="searchId"
                   :loading="loading"
                   v-model="search"
                   :size="size"
                   :is-rounded="isRounded"
                   :placeholder="multiple && modelValue.length ? $t('ui-form.select.selected-placeholder', { count : modelValue.length}) : $t('ui-form.select.search')"
                   v-if="filter && open && !disabled" @keypress.enter="$emit('change', search)">
          <template #suffix>
            <LucideSearch class="h-4 w-4 text-gray-400"/>
          </template>
        </FormInput>
        <div @click="onOpen" :class="['select-input select-none', sizeClass, roundClass, {'is-error' : error, 'ring-1 ring-primary dark:ring-primary' : open, 'opacity-60 cursor-not-allowed' : disabled}]" v-else>
          <span class="block truncate" v-if="!multiple && getLabel">
              <slot name="label" :label="getLabel">
                {{ getLabel }}
              </slot>
          </span>
          <div class="whitespace-nowrap flex flex-wrap pr-24" v-else-if="multiple && modelValue.length">
              <div v-if="showCounter">{{$t('ui-form.select.seelcted', {count : modelValue.length})}}</div>
              <div v-for="item in modelValue" :key="item" class="inline-flex rounded-full items-center leading-4 py-0.5 pl-2.5 pr-1 mr-1 mb-1 text-xs font-medium bg-white dark:bg-white/5 text-lightgray-600" v-else>
                  {{ item[inputLabel] || getLabelFromId(item) }}
                  <button type="button" @click="deleteSelected(item)" class="absoute z-30 flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center hover:bg-gray-200 focus:bg-gray-200">
                      <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                          <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7"/>
                      </svg>
                  </button>
              </div>
          </div>
          <span class="block truncate" v-else-if="valueKey === 'object' && modelValue && modelValue.hasOwnProperty(inputLabel)">
            {{ modelValue[inputLabel] }}
          </span>
          <span class="block truncate text-lightgray-300 dark:text-gray-700" v-else>{{ placeholder }}</span>
          <span class="absolute inset-y-0 right-1 flex items-center pr-8" v-if="selectAll && multiple">
              <button @click="changeAll"
                      class="px-1 py-0.5 text-xs leading-4 rounded inline-flex items-center justify-center bg-white text-lightgray-600 hover:bg-lightgray-100 dark:hover:bg-darkgray-600 focus:bg-gray-200 dark:focus:bg-darkgray-700 border border-transparent">
                  {{ text }}
              </button>
          </span>
          <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronDownIcon class="w-4 h-4 text-gray-500" aria-hidden="true"/>
          </span>
          <span class="absolute inset-y-0 right-8 flex items-center" v-if="clearable && ((getLabel && !multiple) || (multiple && modelValue.length))">
              <button type="button" @click="deleteSelected(null)"
                      class="text-gray-500 ml-0.5 h-4 w-4 bg-gray-100 dark:bg-danger/10 dark:text-danger rounded-full inline-flex items-center justify-center hover:bg-gray-200 focus:bg-gray-200">
                  <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                      <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7"/>
                  </svg>
              </button>
          </span>
        </div>

        <p class="text-xs font-medium text-red-600" v-if="error">{{ error }}</p>

        <template v-if="open && !disabled">
          <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                      leave-to-class="opacity-0">
            <div class="absolute w-full mt-1 py-1 px-1 overflow-auto text-base bg-white dark:bg-darkgray-900 rounded-md shadow-lg max-h-[300px] ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-transaprent dark:scrollbar-thumb-darkgray-500">
              <div class="space-y-1">
                <div v-for="list in filteredList" :key="list">
                  <SelectOption
                      :selected="checkValExist"
                      :item="list"
                      :input-value="inputValue"
                      :input-label="inputLabel"
                      :branch-selectable="branchSelectable"
                      :input-children="inputChildren"
                      v-if="isTree"/>
                  <div @click="!list.disabled ? selectItems(valueKey === 'object' ? list : list[inputValue]) : null"
                       class="option--line selected-list flex text-black"
                       :class="[checkValExist(list) ? 'bg-gray-100' : ''], {'opacity-50 cursor-not-allowed' : list.disabled}"
                       v-else>
                    <div
                        :class="[checkValExist(list)? 'font-medium' : 'font-normal','block truncate', 'md:text-base', ' text-xs']">
                      <slot :item="list" :selected="checkValExist(list)">
                        <span>{{ list[inputLabel] }}</span>
                        <span v-if="checkValExist(list) && !withoutCheckIcon" class="absolute inset-y-0 right-0 flex items-center pr-3 text-green-500">
                          <CheckIcon class="w-5 h-5" aria-hidden="true"/>
                        </span>
                      </slot>
                    </div>
                  </div>
                </div>
                <div v-if="$slots.footer">
                  <slot name="footer"></slot>
                </div>
              </div>

              <div v-if="!filteredList.length" class="flex flex-col dark:text-gray-500">
                <span class="my-2">{{$t('ui-form.select.no-results')}}</span>
                <slot name="create" v-if="create"></slot>
              </div>

            </div>
          </transition>
        </template>
      </div>
    </OnClickOutside>

    <p class="mt-2 text-xs text-lightgray-600 dark:text-gray-400" :class="{'opacity-25' : disabled}" v-if="formHelper">
      {{ formHelper }}
    </p>
  </div>
</template>
<script setup>
import SearchIcon from "~/assets/icons/search.svg"
import {CheckIcon, ChevronDownIcon} from '@heroicons/vue/24/solid';
import collect from 'collect.js';
import {OnClickOutside} from '@vueuse/components'
import SelectOption from "./SelectOption.vue";

// TODO : tabindex geliştirmesi yapılacak
const props = defineProps({
  // for v-model support
  modelValue: {
    type: [String, Number, Object],
    default: "",
    required: false
  },
  options: {
    type: Array,
    default: [],
    required: true
  },
  inputValue: {
    type: [String, Number],
    default: 'value',
    required: false
  },
  inputLabel: {
    type: String,
    default: 'label',
    required: false
  },
  inputChildren: {
    type: String,
    default: 'children',
    required: false
  },
  multiple: {
    type: Boolean,
    default: false,
    required: false
  },
  closeOnSelect: {
    type: Boolean,
    default: true,
    required: false
  },
  remote: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: "Seçim yapınız",
    required: false
  },
  filter: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  create: {
    type: Boolean,
    default: false
  },
  isInfinite: {
    type: Boolean,
    default: false,
  },
  error: {
    type: String,
    default: null
  },
  label: {
    type: String,
    default: null
  },
  labelHelper: {
    type: String,
    default: null
  },
  formHelper: {
    type: String,
    default: null
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isRequired: {
    type: Boolean,
    default: false,
  },
  withoutCheckIcon: {
    type: Boolean,
    default: false,
  },
  selectAll: {
    type: Boolean,
    default: false,
  },
  valueKey: {
    type: String,
    default: 'value',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  showCounter: {
    type: Boolean,
    default: false,
  },
  isTree: {
    type: Boolean,
    default: false,
  },
  branchSelectable: {
    type: Boolean,
    default: false,
  },
  searchId: {
    default: 'select-search'
  },
  size: {
    default: ''
  },
  isRounded: {
    type: Boolean,
    default: false,
  }
})
const emit = defineEmits(['update:modelValue', 'change', 'search-change', 'open', 'close'])

// let loading = ref(false);
let nextItem = 1;
let limit = 10;
let page = 1;
let hasNextPage = true;
let elm = null;

let open = ref(false);
let search = ref("");
let searchInput = ref();
let selectedValue = ref(props.modelValue);
let text = ref("Tümünü Seç");
const options = ref(props.options);

// unref value for arrays & multiple selections
// let selectedValues = props.modelValue || []

const setValue = (value) => {
  if (!props.multiple) {
    if (typeof value === 'object' && value !== null) {
      const collectQuery = collect(props.options);
      let collection;
      if (props.valueKey === 'object') {
        collection = collectQuery.where(props.inputValue, '=', value[props.inputValue]).first();
      } else {
        collection = collectQuery.where(props.inputValue, '=', value).first();
      }

      if (typeof collection === 'undefined') {
        selectedValue.value = null;
      } else {
        value[props.inputValue] = collection[props.inputValue];
        value[props.inputLabel] = collection[props.inputLabel];
        selectedValue.value = collection;
      }
    } else {
      selectedValue.value = value;
    }
  } else {
    let tempArray = [];
    props.options.forEach((elm, index) => {
      if (value.length >= 1) {
        value.forEach(item => {
          if (typeof item === 'object') {
            if (elm[props.inputValue.toString()] === item[props.inputValue]?.toString()) {
              tempArray.push(elm);
            }
          } else {
            if (elm[props.inputValue.toString()] === item?.toString()) {
              tempArray.push(elm);
            }
          }
        })
      }
    })

    let modelArray = collect(tempArray).pluck(props.inputLabel).toArray();
    if (props.valueKey === 'object') {
      modelArray = tempArray;
    }

    // selectedValue.value = collect(props.options).whereIn(props.inputLabel, modelArray).toArray();
    selectedValue.value = modelArray;
  }
}

watch(props, (value) => {
  setValue(value.modelValue)
});

const selected = computed({
  get() {
    return selectedValue.value;
  },
  set(value) {
    setValue(value);
    emit('update:modelValue', value)
    emit('change', value)
  },
})

const filteredList = computed({
  get() {
    return options.value;
  },
  set(val) {
    options.value = val;
  }
})

/**
 * Functions
 */

// Search query in nested data
function searchInOptions(options, query, data = []) {

  if (!query) {
    return options;
  }

  let searchQuery = new RegExp(`^.*${query}.*$`, 'giu');
  let array = collect(data);

  for (let [key, value] of Object.entries(options)) {

    if (value[props.inputLabel].match(searchQuery)) {
      array.push(value);
    }

    if (typeof value == 'object' && value.hasOwnProperty(props.inputChildren) && value[props.inputChildren]) {

      let found = searchInOptions(value[props.inputChildren], query, array);

      if (typeof found === 'object' && found.length > 0) {

        // If the object was found in the recursive call, bubble it up.
        array.merge(found);
      }
    }
  }

  return array.unique(props.inputLabel).toArray()

}

// Close select box
const hide = () => {
  open.value = false;
}

// Delete selected item on click
const deleteSelected = (value) => {
  if (props.multiple) {
    const valueArray = props.modelValue || [];
    if (value) {
      selected.value = valueArray.filter(x => x !== value);
    } else {
      selected.value = [];
    }

    if (valueArray.length === 0) {
      text.value = "Tümünü Seç"
    }
  } else {
    selected.value = "";
  }
}

// check the value if exist or not exist
const checkValExist = (val) => {
  if (props.multiple) {
    const valueArray = props.modelValue || [];
    if (props.valueKey === 'object') {
      return valueArray.some(elm => elm[props.inputValue] === val[props.inputValue])
    } else {
      return valueArray.some(elm => elm === val || elm === val[props.inputValue])
    }
  } else {
    if (typeof val === 'object') {
      return selected.value === val[props.inputValue]
    } else {
      return selected.value === val
    }
  }
}

// select and push to model value on click
const selectItems = async (item) => {
  // console.log('item', item)
  if (props.multiple) {
    // unselect if exist
    if (checkValExist(item)) {
      await deleteSelected(item);
    } else {
      const valueArray = props.modelValue || [];
      valueArray.push(item)
      selected.value = valueArray;

      // console.log('valueArray', valueArray)
      // if (props.valueKey === 'object') {
      //
      //     await selectedValues.value.push(item)
      //     console.log('after', selectedValues)
      // } else {
      //     await selectedValues.value.push(item)
      // }
    }

    if (props.valueKey === 'object') {

    } else {
      // selected.value = collect(selectedValues).pluck(props.inputValue).toArray()
    }
  } else {
    if (checkValExist(item)) {
      await deleteSelected(item);
    } else if (props.valueKey === 'object' || props.isTree) {
      selected.value = item
    } else {
      if (typeof item === 'object') {
        selected.value = item[props.inputValue]
      } else {
        selected.value = item // single values
      }
    }
  }

  search.value = "";

  // close on select event
  if (props.closeOnSelect) {
    await hide();
  }

}

const changeAll = () => {
  if (text.value === "Tümünü Seç") {
    text.value = "Tümünü Çıkar"
    selectedValue.value = collect(props.options).pluck(props.inputLabel).toArray();
  } else {
    text.value = "Tümünü Seç"
    selectedValue.value = [];
  }
}

const onOpen = async () => {

  if (props.disabled) {
    return;
  }

  // toggle
  open.value = !open.value;

  if (props.filter) {
    await nextTick(() => {
      document.getElementById(props.searchId).focus()
    })
  }

  setTimeout(() => {
    const list = document.getElementsByClassName("selected-list");
    if (list.length > 0)
      elm = list[0].parentElement;
    addEvent(elm);
  }, 200);
}

const addEvent = (elm) => {
  if (elm) {
    if (props.isInfinite) {
      elm.addEventListener('scroll', e => {
        if (elm.scrollTop + elm.clientHeight >= elm.scrollHeight - 1) {
          // loadMore();
        }
      })
    }
  }
}

const getLabelFromId = (id) => {
  const label = findNested(props.options, id);
  return label ? label[props.inputLabel] : null;
}

onMounted(() => {
  if (
      props.multiple &&
      Array.isArray(props.modelValue) &&
      props.modelValue.length > 0
  ) {
    // placeholder.value = ''
  }
})

const getLabel = computed(() => {
  if (props.isTree) {
    const collect = findNested(props.options);
    return collect ? collect[props.inputLabel] : null;
  } else {
    return collect(filteredList.value).where(props.inputValue, '=', selected.value).pluck(props.inputLabel).first()
  }
});

watch(() => props.options, (value) => {
  filteredList.value = value;
});

watch(search, (searchValue, oldSearchValue) => {
  emit('search-change', searchValue)

  filteredList.value = searchInOptions(props.options, searchValue);
});

watch(open, (openValue) => {
  if (openValue) {
    emit('open', true)
  } else {
    emit('close', true)
  }
});

function findNested(options, val = null) {
  // Base case
  const selectedValue = val ? val : selected.value
  for (let [key, value] of Object.entries(options)) {

    if (value[props.inputValue] === selectedValue) {
      return value;
    }

    if (typeof value == 'object' && value.hasOwnProperty(props.inputChildren) && value[props.inputChildren]) {

      let found = findNested(value[props.inputChildren], val);
      if (found) {
        // If the object was found in the recursive call, bubble it up.
        return found;
      }
    } else if (typeof value == 'object') {
      // not children
      if (value[props.inputValue] === selectedValue) {
        return value;
      }
    }
  }
}

// if (props.isTree) {
//   emitter.on("selectedValueInSelectOption", async function (value) {
//     // *Listen* for event
//     await selectItems(props.valueKey === 'object' ? value : value[props.inputValue])
//   });
// }

const sizeClass = computed(() => {
  if (props.size === 'sm') {
    return 'is-small'
  } else {
    return ''
  }
})

const roundClass = computed(() => {
  if (props.isRounded) {
    return 'is-rounded'
  } else {
    return ''
  }
})


</script>
