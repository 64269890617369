const elements = ref([]);

export default () => {

    const hideOther = (id) => {
        const items = elements.value.filter((elm) => {
            return elm.getAttribute("data-id") !== id.toString();
        });
        items.forEach((elm) => elm.click());
    };

    const doClose = (close) => {
        close();
    };

    return {elements, hideOther, doClose}
}